import axios from 'axios';
import filesize from 'filesize';
import vSelect from 'vue-select';
import BootstrapVue from 'bootstrap-vue';
import Vue from "vue";

window.Vue = Vue;
Vue.use(BootstrapVue)

window._ = require('lodash');
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');

/** AXIOS STUFF **/
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.filesize = filesize;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
